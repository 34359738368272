
.content-export-tile {
	display: flex;
	flex-direction: column;


	gap: 16px;

	height: fit-content;

	h3 {
		font-size: 16px;
		margin: 12px 0;
	}
}

.export-error {
	margin-bottom: 20px;
}
